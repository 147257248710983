import React from 'react';
import Layout, { Main } from '../wrappers/layout';
import { MakerList } from '../components/maker-list';
import { SWATCHES } from "../components/constants"

export default function Makers() {
	return (
		<Layout>
			<Main>
				<MakerList backgroundColor={SWATCHES["red-awe"]} />
			</Main>
		</Layout>
	)
}

export { MakersMeta as Head } from "../components/meta"