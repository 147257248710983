import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Link from '../components/link';
import useMedia from '../hooks/useMedia';

export function useMakerList() {
	const data = useStaticQuery(graphql`
    {
			makers: allContentfulMaker(
				filter: {makers_page: {elemMatch: {contentful_id: {eq: "7dHZPcK7BfCtHyMfLSC3ze"}}}}
				sort: {fields: name}
			) {
				nodes {
					name
					slug
					places {
						name
					}
				}
			}
    }
  `)
  return data?.makers?.nodes
}

export function MakerList({ backgroundColor }) {

	const makers = useMakerList()

	const columnCount = useMedia(
		// Media queries
		['(min-width: 1280px)', '(min-width: 768px) and (orientation: landscape)', '(max-width: 595px)'],
		// Column counts (relates to above media queries by array index)
		[7, 5, 3],
		// Default column count
		7
	);
	const remainder = makers.length % columnCount
	const remainderLength = remainder ? columnCount - remainder : 0

	return (
		<div className="mt maker-chequerboard" style={{ backgroundColor }}>
			{makers?.map(maker => 
				<Link to={`/makers/${maker.slug}`} className="maker-card" key={maker.slug}>
					<h1>{maker.name}</h1>
				</Link>
			)}
			{Array.from({ length: remainderLength}, (x, i) => 
				<div className="maker-card no-hover" key={`remainder-${i}`} />
			)}
		</div>
	)
}