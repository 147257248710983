import { useState, useEffect } from "react";

// NOTE: Usage...
// const columnCount = useMedia(
//   // Media queries
//   ['(min-width: 1500px)', '(min-width: 1000px)', '(min-width: 600px)'],
//   // Column counts (relates to above media queries by array index)
//   [5, 4, 3],
//   // Default column count
//   2
// );

// Hook
export default function useMedia(queries, values, defaultValue) {
  // Array containing a media query list for each query
  const mediaQueryLists = typeof window !== `undefined` ? queries.map(q => window.matchMedia(q)) : null;

  // Function that gets value based on matching media query
  const getValue = () => {
    // Get index of first media query that matches
    const index = mediaQueryLists ? mediaQueryLists.findIndex(mql => mql.matches) : 0;
    // Return related value or defaultValue if none
    return typeof values[index] !== 'undefined' ? values[index] : defaultValue;
  };

  // State and setter for matched value
  const [value, setValue] = useState(getValue);

  useEffect(() => {
		// Event listener callback
		// Note: By defining getValue outside of useEffect we ensure that it has ...
		// ... current values of hook args (as this hook callback is created once on mount).
		const handler = () => setValue(getValue);
		// Set a listener for each media query with above handler as callback.
		mediaQueryLists.forEach(mql => mql.addListener(handler));
		// Remove listeners on cleanup
		return () => mediaQueryLists.forEach(mql => mql.removeListener(handler));
		// eslint-disable-next-line
	}, []);

  return value;
}